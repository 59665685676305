<template>
  <div class="fp-wrapper">
    <div class="modal-window">
      <div class="content content_form">
        <form-header />
        <h2 class="h2 h2_bold content__h2">Регистрация</h2>

        <form-observer :validation-schema="registrationSchema" @submit="onSubmit">
          <input-text
            name="email"
            type="email"
            label-text="Электронная почта"
            placeholder="Введите e-mail"
          />
          <input-phone
            ref="inputPhone"
            name="phone"
            label-text="Телефон"
            placeholder="Введите телефон"
            @updateMask="updateMask"
          />
          <input-text
            name="lastname"
            label-text="Фамилия"
            placeholder="Введите фамилию"
          />
          <input-text
            name="firstname"
            label-text="Имя"
            placeholder="Введите имя"
          />
          <input-text
            name="middlename"
            label-text="Отчество"
            placeholder="Введите отчество"
          />
          <input-password
            name="password"
            label-text="Пароль"
            :is-show-password="true"
            placeholder="Введите пароль"
          />
          <div class="content__section">
            <button
              class="button"
              :class="{'button_disabled': loading }"
            >
              Зарегистрироваться
            </button>
          </div>
        </form-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as FormObserver } from 'vee-validate'
import InputText from '@/components/common/InputText'
import InputPhone from '@/components/common/InputPhone'
import InputPassword from '@/components/common/InputPassword'
import { setRegistrationSchema } from '@/validation'
import FormHeader from '@/components/common/FormHeader'
import { mapActions } from 'vuex'

export default {
  name: 'Registration',
  components: {
    FormObserver,
    InputText,
    InputPhone,
    InputPassword,
    FormHeader
  },
  data () {
    const registrationSchema = setRegistrationSchema(this)
    return {
      mask: null,
      registrationSchema,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'setUser'
    ]),
    onSubmit (values) {
      this.loading = true
      if (values.phone) {
        values.phone = this.$refs.inputPhone.fullValue
      }
      this.$axios.post(this.getPathRequest('registration'), values)
        .then(response => {
          this.setUser(response.data)
          this.$router.push({ name: 'EmailConfirm' })
        })
        .catch(error => {
          this.openModal('ErrorModal', {
            title: 'Ошибка регистрации',
            text: error.response.data.message
              ? error.response.data.message
              : 'Во время регистрации произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateMask (value) {
      this.mask = value.mask
    }
  }
}
</script>
